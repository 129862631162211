<template>
  <div class="carousel" :style="{ minWidth: width, height: '100%' }">
    <el-carousel
      :interval="5000"
      arrow="always"
      :height="height"
      :indicator-position="position"
      :direction="direction"
      :loop="loop"
      ref="carousel"
      :class="{ games: type === 'games' }"
      @change="change"
    >
      <el-carousel-item v-for="item in length" :key="item">
        <slot :item="item" />
      </el-carousel-item>
    </el-carousel>
    <div class="scrolld" v-if="direction === 'vertical'">
      <img src="../../assets/make/news/more.png" class="img1" @click="prev" />
      <div class="scroll"></div>
      <img src="../../assets/make/news/more.png" class="img2" @click="next" />
    </div>
    <div class="games-side" v-if="type === 'games'" :style="{ height: height }">
      <div class="games-lan" ref="games" id="games" :style="{ height: height }">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="games-item"
          :class="{ gamesLast: index === list.length - 1 }"
        >
          <div
            class="ddddimg"
            @mouseenter="mouseenter(index)"
            @click="active(index)"
            @mouseleave="mouseleave(index)"
          >
            <transition-group name="title">
              <img :src="item.icon" class="act-img" key="1" />
              <div class="dark" v-if="!(copylist[index].focus || copylist[index].act)" key="2"></div>
            </transition-group>
          </div>
          <div class="show-name">
            <transition-group name="title">
              <div v-if="copylist[index].focus || copylist[index].act" class="show-named"></div>

              <div v-if="copylist[index].focus" class="cc" key="1"></div>
              <div v-if="copylist[index].focus" class="title" key="2">{{ item.name }}</div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElCarousel, ElCarouselItem } from "element-plus";
export default {
  components: {
    // DrHeader,
    ElCarousel,
    ElCarouselItem,
  },
  props: {
    height: {
      type: String,
      default: "833px",
    },
    width: {
      type: String,
      default: "100%",
    },
    position: {
      type: String,
      default: "",
    },
    direction: {
      type: String,
      default: "horizontal",
    },
    list: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    news: {
      type: Object,
      default: () => { },
    },
    count: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      length: 1,
      copylist: [],
    };
  },
  watch: {
    count: {
      handler(val) {
        this.length = val % 5 === 0 ? val / 5 : Math.floor(val / 5) + 1;
        console.warn(this.length, "length");
      },
      immediate: true,
    },
    // show:{

    // },
    list: {
      handler(val) {
        if (this.type === "culture") {
          // console.warn(this.list.length,"ffff")
          // this.length =
          //   val.length % 7 === 0
          //     ? val.length / 7
          //     : Math.floor(val.length / 7) + 1;
          // console.warn(this.length, "ggg");
          this.length = val.length;
        } else if (this.type === "news") {
          // console.warn(val,"val")
          // this.length =
          //   val.length % 5 === 0
          //     ? val.length / 5
          //     : Math.floor(val.length / 5) + 1;
          // console.warn(this.length, "length");
        }
      },
      immediate: true,
    },
  },
  created() {
    //初始化
    if (this.type === "games") {
      this.length = this.list.length;
      for (let i = 0; i < this.list.length; i++) {
        this.copylist.push(
          JSON.parse(
            JSON.stringify({
              focus: false,
              act: false,
            })
          )
        );
      }
      this.copylist[0].act = true;
    } else if (this.type === "culture") {
      // this.length =
      //   this.list.length % 7 === 0
      //     ? this.list.length / 7
      //     : Math.floor(this.list.length / 7) + 1;
      //     console.warn(this.length,"ggg")
    } else if (this.type === "news") {
      return;
    } else {
      this.length = this.list.length;
    }
  },
  mounted() {
    if (this.list.length === 1) {
      document.getElementsByClassName("el-carousel__arrow--left")[0].style.display = "none"
      document.getElementsByClassName("el-carousel__arrow--right")[0].style.display = "none"
      console.warn(document.getElementsByClassName("el-carousel__arrow--left"));
    }
  },
  methods: {
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    mouseenter(index) {
      this.copylist[index].focus = true;
    },
    mouseleave(index) {
      this.copylist[index].focus = false;
    },
    change(index, old) {
      if (this.type === "games") {
        this.copylist[old].act = false;
        this.copylist[index].act = true;
      } else if (this.type === "news") {
        if (index === this.news.page && this.list.length < this.count) {
          console.warn(this);
          this.$emit("up");
        }
        // if (index === this.news.page && this.show) {
        //   console.warn(this);
        //   this.$emit("up");
        // }
      }
    },
    //是否为选中状态
    active(index) {
      this.$refs.carousel.setActiveItem(index);
    },
  },
};
</script>
<style scoped>
.carousel {
  position: relative;
  width: 100%;
  transform: translateZ(0);
}
.games-lan {
  width: 130px;
  height: 904px;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  /* overflow: hidden; */
}
.games-side {
  position: absolute;
  left: 0;
  top: 0;
  width: 130px;
  height: 904px;
  background: rgba(0, 0, 0, 0.5);
  /* z-index: 1; */
}
.dark {
  position: absolute;
  left: 0;
  top: 0;
  width: 84px;
  height: 83px;
  background: rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  z-index: 5;
}
.games-lan::-webkit-scrollbar {
  display: none;
}
/* /deep/ .el-carousel{
  height: 100%;
} */
.games-item {
  width: 100%;
  height: 83px;
  border-radius: 10px;
  margin-bottom: 18px;
  background-color: transparent;
  position: relative;
  display: flex;
  margin-left: calc(65px - 42px);
  /* cursor: pointer; */
}
.gamesLast {
  margin-bottom: unset;
}
.games-item-img {
  /* position: absolute; */
  /* left: calc(65px - 42px);
  top: 0; */
  width: 84px;
  height: 83px;
  border-radius: 10px;
  z-index: 3;
}
.ddddimg {
  width: 84px;
  height: 83px;
  position: relative;
  z-index: 5;
  cursor: pointer;
}
.act-img {
  position: absolute;
  left: calc(50% - 42px);
  top: 0;
  width: 84px;
  height: 83px;
  border-radius: 10px;
  z-index: 4;
}
.show-name {
  position: absolute;
  left: calc(42px - 65px);
  top: calc(50% - 31px);
  display: flex;
  background-color: transparent;
  opacity: 1 !important;
  /* z-index: 3001; */
}
.show-named {
  width: 4px;
  height: 62px;
  /* background:transparent; */
  background-color: #CC73FF;
  /* background:white; */
  z-index: 5000;
  /* opacity: 1 ; */
  filter: brightness(1);
}

.title {
  background-color: rgba(0, 0, 0, 0.45);
  color: white;
  padding: 20px;
  display: inline-block;
  white-space: nowrap;
  position: relative;
  /* z-index: 66666; */
}

.cc {
  width: 130px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.45);
}

.scrolld {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -45px;
  top: 20%;
}

.scrolld img {
  width: 24px;
  height: 15px;
  cursor: pointer;
}
.scroll {
  width: 1px;
  height: 72px;
  background: #212121;
  position: relative;
  opacity: 0.2;
  margin-bottom: 10px;
}

.img1 {
  transform: rotate(180deg);
  margin-bottom: 10px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  /* opacity: 0.75; */
  /* line-height: 833px; */
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: transparent;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: transparent;
}

.carousel >>> .el-carousel__arrow {
  border-radius: unset !important;
  background-color: transparent !important;
}

.carousel >>> .el-icon-arrow-left:before {
  content: "";
  /* background-color: teal; */

  background-image: url("../../assets/home-carousel/left-1.png");
  background-size: cover;
  /* background: url("../assets/home-carousel/头图左箭头-选中.png") fixed no-repeat cover; */
  display: inline-block;
  width: 32px;
  height: 51px;
}

.carousel >>> .el-icon-arrow-left:hover:before {
  background-image: url("../../assets/home-carousel/left-2.png");
}
.carousel >>> .el-carousel__indicator--horizontal {
  height: 4px;
}
.carousel >>> .el-icon-arrow-right:before {
  content: "";
  /* background-color: teal; */

  background-image: url("../../assets/home-carousel/right-1.png");
  background-size: cover;
  /* background: url("../assets/home-carousel/头图左箭头-选中.png") fixed no-repeat cover; */
  display: inline-block;
  width: 32px;
  height: 51px;
}

.carousel >>> .el-icon-arrow-right:hover:before {
  background-image: url("../../assets/home-carousel/right-2.png");
}

.carousel >>> .el-carousel__indicator.is-active button {
  opacity: 1;
  background-color: #CC73FF;
}

.games >>> .el-carousel__arrow {
  display: none;
}
</style>