<template>
  <div class="home">
    <dr-carousel :list="list" v-if="list.length !== 0" :height="height">
      <template v-slot:default="slotPops">
        <img :src="list[slotPops.item - 1]" class="header-img" />
      </template>
    </dr-carousel>
    <div class="backgounf">
      <div class="more">
        <div class="cc">
          <img src="../assets/index/more1.png" class="more-i" />
          <img src="../assets/index/more2.png" class="more-i back" />
        </div>
        <div class="cc">
          <img src="../assets/index/more1.png" class="more-i" />
          <img src="../assets/index/more2.png" class="more-i back jj" />
        </div>
      </div>
      <div class="content">
        <div class="content-middle">
          <div
            class="recruit small"
            @mouseenter="reshow = true"
            @mouseleave="reshow = false"
            @click="go('recruit')"
          >
            <transition-group name="faded">
              <img
                key="1"
                v-if="reshow"
                src="../assets/background/recruit/1.png"
                class="content-img ddd"
              />
              <img :src="change('recruitment')" class="content-img" key="2" />
            </transition-group>
            <div class="bottom">
              <div class="bottom-m">
                <div>招聘中心</div>

                <img src="../assets/background/recruit/2.png" class="fff" />
                <transition name="fade">
                  <img
                    v-if="reshow"
                    src="../assets/background/recruit/3.png"
                    class="act-img"
                  />
                </transition>
              </div>
            </div>
          </div>
          <!-- <div
            class="production big"
            @click="go('games')"
            @mouseenter="prshow = true"
            @mouseleave="prshow = false"
          >
            <transition-group name="faded">
              <img
                v-if="prshow"
                src="../assets/background/production/1.png"
                class="content-img ddd"
                key="1"
              />
              <img :src="change('products')" class="content-img" key="2" />
            </transition-group>
            <div class="bottom">
              <div class="bottom-m">
                <div>产品中心</div>

                <img src="../assets/background/production/2.png" class="fff" />
                <transition name="fade">
                  <img
                    v-if="prshow"
                    src="../assets/background/production/3.png"
                    class="act-img"
                  />
                </transition>
              </div>
            </div>
          </div> -->
          <div
            class="make big"
            @click="go('make')"
            @mouseenter="mashow = true"
            @mouseleave="mashow = false"
          >
            <transition-group name="faded">
              <img
                key="1"
                v-if="mashow"
                src="../assets/background/make/1.png"
                class="content-img ddd"
              />
              <img :src="change('company')" class="content-img" key="2" />
            </transition-group>
            <div class="bottom">
              <div class="bottom-m">
                <div>公司介绍</div>

                <img src="../assets/background/make/2.png" class="fff" />
                <transition name="fade">
                  <img
                    v-if="mashow"
                    src="../assets/background/make/3.png"
                    class="act-img"
                  />
                </transition>
              </div>
            </div>
          </div>
          <div
            class="contact small"
            @mouseenter="coshow = true"
            @click="go('contact')"
            @mouseleave="coshow = false"
          >
            <transition-group name="faded">
              <img
                key="1"
                v-if="coshow"
                src="../assets/background/contact/1.png"
                class="content-img ddd"
              />
              <img :src="change('contacts')" class="content-img" key="2" />
            </transition-group>
            <div class="bottom">
              <div class="bottom-m">
                <div>联系我们</div>

                <img src="../assets/background/contact/2.png" class="fff" />
                <transition name="fade">
                  <img
                    v-if="coshow"
                    src="../assets/background/contact/3.png"
                    class="act-img"
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DrCarousel from "../components/DrCarousel/index.vue";
import { banners, getHomeList } from "../utils/api";
export default {
  components: {
    DrCarousel,
  },
  data() {
    return {
      height: "833px",
      prshow: false,
      mashow: false,
      coshow: false,
      reshow: false,
      list: [],
      imgList: [],
    };
  },
  methods: {
    go(index) {
      this.$router.push(`/${index}`);
    },
    change(name) {
      for (let a = 0; a < this.imgList.length; a++) {
        if (this.imgList[a].tag === name) {
          return this.imgList[a].url;
        }
      }
    },
    getHieght() {
      let height = (window.innerWidth / 1920) * 833;
      // console.warn(this.height);
      this.height = height + "px";
      this.width = window.innerWidth + "px";
    },
  },
  created() {
    banners().then((res) => {
      this.list = res.data.data.banners;
    });
    getHomeList().then((res) => {
      this.imgList = res.data.data.list;
    });
  },
  mounted() {
    this.getHieght();

    window.addEventListener("resize", this.getHieght);

    window.scrollTo({
      //滚动到元素位置

      top: 0,
      behavior: "smooth", // 平滑滚动
    });
  },

  unmounted() {
    window.removeEventListener("resize", this.getHieght);
  },
};
</script>
<style scoped>
.home {
  /* height: 1900px; */
  background-color: transparent;
  /* background-image: url("../assets/background/大背景.png");

  background-size: 100% 100%;

  background-position: center;
  background-repeat: no-repeat; */
  /* background-attachment: fixed; */
}
.header-img {
  width: 100%;
  height: 833px;
}
.content {
  min-height: 897px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fff {
  width: 100%;
}
.content-middle {
  max-width: calc(861px + 408px + 9px);
  height: calc(296px + 389px + 80px);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.content-img {
  position: absolute;
  width: inherit;
  height: 296px;
  /* opacity: 0.8; */
}
.small {
  width: 408px;
  height: 376px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.backgounf{
  background: url("../assets/bg1.jpg") no-repeat center;
}
.big {
  height: 376px;
  width: 861px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  line-height: 80px;
  /* opacity: 0.8; */
}
.act-img {
  width: 100%;
  height: 80px;
  /* opacity: 1 !important; */
}
.bottom-m {
  position: relative;
  font-size: 24px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #ffffff;
}
.bottom-m div {
  position: absolute;
  left: 28px;
  margin-left: 28px;
  z-index: 5;
}
.bottom-m img {
  position: absolute;
  left: 0;
}
.ddd {
  z-index: 8;
}
.faded-enter-active,
.faded-leave-active {
  transition: all 0.5s ease;
}

.faded-enter-from,
.faded-leave-to {
  opacity: 0;
  /* width: 0%; */
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.more {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.more-i {
  position: absolute;
  left: 0;
  top: 0;
  top: 10px;
  width: 36px;
  filter: brightness(150%);
}

.cc {
  position: relative;
  width: 36px;
  height: 13px;
  animation: linear 1s mymoved infinite;
}
@keyframes mymoved {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(20px);
  }
}
.back {
  animation: mymove 3s infinite;
  opacity: 0;
  z-index: 2;
}

@keyframes mymove {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes mymove1 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    /* transform: scale(1.1); */
  }
}

.jj {
  /* animation: mymove1 3s infinite; */
  animation-delay: 1.1s;
}
</style>